/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = { hiylloID: string; };
export type ResponseType = { email: string; password: string; };
export const Endpoint = "admin/create-employee-account";
export const Method = "POST";

export type Plug = {
    params: ParamsType,
    response: ResponseType,
    method: typeof Method,
    endpoint: typeof Endpoint,
};export const paramsSchema = {"type":"object","properties":{"hiylloID":{"type":"string"}},"required":["hiylloID"],"$schema":"http://json-schema.org/draft-07/schema#"}