/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  email: string;
};
export type ResponseType = void;
export const Endpoint = 'accounts/reset-password';
export const Method = 'POST';
export const isPublic = true;

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
}export const paramsSchema = {"type":"object","properties":{"email":{"type":"string"}},"required":["email"],"$schema":"http://json-schema.org/draft-07/schema#"}