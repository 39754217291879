/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { HashedString, HashedStringSchemaType } from '../../types/hashed-string';
import { createJSONSchemaFromRequiredProperties, schemaOr, StringSchemaType, VoidSchemaType } from '../../types/json-schema';
import { U2FPack, U2FPackSchema } from '../../types/u2f-pack';

export type ParamsType = { email: string, password: HashedString, u2fPack?: U2FPack | {mfaBypass:{uuid:string; token:string;}} | null };
export interface ResponseType { token: string }
export const Endpoint = 'login/login-with-email-and-password';
export const Method = 'POST';
export const isPublic = true;

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
}

export const paramsSchema = {
  type: 'object',
  properties: {
    email: { type: 'string' },
    password: {
      type: 'object',
      properties: {
        digest: { type: 'string' },
        algorithm: { type: 'string' }
      },
      required: ['algorithm', 'digest']
    },
    u2fPack: {
      anyOf: [{
        type: 'object',
        properties: {
          challengeId: { type: 'string' },
          credential: { $ref: '#/definitions/AuthenticationCredentialJSON' }
        },
        required: ['challengeId', 'credential']
      }, {
        type: 'object',
        properties: {
          mfaBypass: {
            type: 'object',
            properties: {
              uuid: { type: 'string' },
              token: { type: 'string' }
            },
            required: ['token', 'uuid']
          }
        },
        required: ['mfaBypass']
      }, { type: 'null' }]
    }
  },
  required: ['email', 'password'],
  definitions: {
    AuthenticationCredentialJSON: {
      type: 'object',
      properties: {
        rawId: { type: 'string' },
        response: { $ref: '#/definitions/AuthenticatorAssertionResponseJSON' },
        clientExtensionResults: { $ref: '#/definitions/AuthenticationExtensionsClientOutputs' },
        id: { type: 'string' },
        type: { type: 'string' }
      },
      required: ['clientExtensionResults', 'id', 'rawId', 'response', 'type']
    },
    AuthenticatorAssertionResponseJSON: {
      type: 'object',
      properties: {
        authenticatorData: { type: 'string' },
        clientDataJSON: { type: 'string' },
        signature: { type: 'string' },
        userHandle: { type: 'string' }
      },
      required: ['authenticatorData', 'clientDataJSON', 'signature']
    },
    AuthenticationExtensionsClientOutputs: {
      type: 'object',
      properties: {
        appid: { type: 'boolean' },
        credProps: {
          type: 'object',
          properties: { rk: { type: 'boolean' } }
        },
        uvm: {
          type: 'array',
          items: {
            type: 'array',
            items: { type: 'number' }
          }
        }
      }
    }
  },
  $schema: 'http://json-schema.org/draft-07/schema#'
};
