import React from 'react';
import { styled } from '@hiyllo/ux/styled';

const Container = styled('div', ({ $theme }) => ({
  background: $theme.background1,
  color: $theme.foreground,
  gap: 20,
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));

const LoadingCircleInner = styled('div', ({ $theme }) => ({
  background: $theme.background1,
  height: 250,
  width: 250,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  position: 'absolute',
  top: 2.5,
  left: 2.5
}));

const LoadingCircleOuter = styled('div', ({ $theme }) => ({
  background: $theme.buttonBackground,
  height: 255,
  width: 255,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  position: 'absolute',
  top: 0,
  left: 0
}));
const LoadingCircleContainer = styled('div', ({ $theme }) => ({
  background: $theme.buttonBackground,
  height: 255,
  width: 255,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  position: 'relative'
}));

export const LoadingPage = React.memo(function LoadingPage (): JSX.Element {
  return (
    <Container>
      <LoadingCircleContainer>
        <LoadingCircleOuter className='LoadingCircle'/>
        <LoadingCircleInner>

        </LoadingCircleInner>
      </LoadingCircleContainer>
    </Container>
  );
});
