/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { SSOAppScope } from "../../types/sso-apps";

export type ParamsType = {
  appUUID: string;
};
export type ResponseType = {
  appName: string;
  redirectURI: string;
  scopes: SSOAppScope[];
  organization: {
    legalOwner: string;
  }
}
export const Endpoint = 'sso-apps/get-sso-app-consent-details';
export const Method = 'POST';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
}export const paramsSchema = {"type":"object","properties":{"appUUID":{"type":"string"}},"required":["appUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}