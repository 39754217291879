import React from 'react';
import { styled } from '@hiyllo/ux/styled';
import { Input } from '@hiyllo/ux/input';
import { Button } from '@hiyllo/ux/button';

import * as CreateEmployeeAccountBP from '../blueprints/admin/create-employee-account';
import { seamlessClient } from '../seamless-client';

const Container = styled('div', ({ $theme }) => ({
  background: $theme.background1,
  color: $theme.foreground,
  height: '100%'
}));

const Inner = styled('div', ({ $theme }) => ({
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  height: '100%',
  fontWeight: 'normal'
}));

const Header = styled('div', { fontSize: 30 });

export const CreateEmployeeAccount = React.memo(function CreateEmployeeAccount (): JSX.Element {
  const createEmployeeAccountMutation = seamlessClient.useMutation<CreateEmployeeAccountBP.Plug>(CreateEmployeeAccountBP);
  const [employeeId, setEmployeeId] = React.useState<string>('');
  const [password, setPassword] = React.useState<string | null>(null);

  const createEmployeeAccount = React.useCallback(() => {
    void createEmployeeAccountMutation.call({ hiylloID: `${employeeId.trim().toLowerCase()}@hiyllo.io` }).then(res => {
      setPassword(res.password);
    });
  }, [createEmployeeAccountMutation, employeeId]);

  if (password != null) {
    return (
      <Container>
        <Inner>
          <Header>
            Employee Account Created
          </Header>
          <Input
            label="Email"
            value={employeeId + '@hiyllo.io'}
          />
          <Input
            label="Password"
            value={password}
          />
        </Inner>
      </Container>
    );
  }

  return (
    <Container>
      <Inner>
        <Header>
          Create Employee Account
        </Header>
        <Input
          label="Employee ID (before the @)"
          value={employeeId}
          onChangeValue={setEmployeeId}
        />
        <div style={{
          display: 'flex',
          flexDirection: 'row'
        }}>
          <Button
            label="Create Employee"
            onClick={createEmployeeAccount}
            isLoading={createEmployeeAccountMutation.isLoading}
          />
        </div>
      </Inner>
    </Container>
  );
});
