import React from 'react';
import { getSavedTokenPackage, seamlessAuth } from '../seamless-client';
import { LoadingPage } from '../components/loading-page';
import { Login } from '../views/login';

export const AuthenticationProvider = React.memo(function AuthenticationProvider (props: React.PropsWithChildren): JSX.Element {
  const isAuthenticated = getSavedTokenPackage() != null;
  const authState = seamlessAuth.useAuthState();

  if (authState == null && !window.location.pathname.startsWith('/sp')) {
    if (isAuthenticated) {
      return <LoadingPage/>;
    }

    return <Login/>;
  }

  return <>{props.children}</>;
});
