import React from 'react';
import { AuthenticationProvider } from './authentication-provider';
import { Router } from './router';
import { UXThemeProvider } from './theme-provider';
import { DialogProvider } from '@hiyllo/ux/dialogs';
import { FontAwesomeIconCtx } from '@hiyllo/ux/platform';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Main = React.memo(function Main (): JSX.Element {
  return (
    <FontAwesomeIconCtx.Provider value={FontAwesomeIcon as any}>
      <UXThemeProvider>
        <DialogProvider>
          <AuthenticationProvider>
            <Router/>
          </AuthenticationProvider>
        </DialogProvider>
      </UXThemeProvider>
    </FontAwesomeIconCtx.Provider>
  );
});
