/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
  appUUID: string;
};
export type ResponseType = {
  redirectURI: string;
}
export const Endpoint = 'sso-apps/login-to-sso-app';
export const Method = 'POST';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
}export const paramsSchema = {"type":"object","properties":{"appUUID":{"type":"string"}},"required":["appUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}