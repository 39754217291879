import React from 'react';
import { styled } from '@hiyllo/ux/styled';

import * as BP from '../blueprints/sso-apps/get-sso-app-consent-details';
import * as LoginBP from '../blueprints/sso-apps/login-to-sso-app';
import { seamlessClient } from '../seamless-client';
import { useParams } from 'react-router-dom';
import { LoadingSpinner } from '@hiyllo/ux/loading-spinner';
import { Typography } from '@hiyllo/ux/typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faShield } from '@fortawesome/pro-light-svg-icons';
import { Button } from '@hiyllo/ux/button';
import { useAlertDialog } from '@hiyllo/ux/dialogs';

const Page = styled('div', ({ $theme }) => ({
  height: '100%',
  width: '100%',
  background: $theme.background1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
}));

const Card = styled('div', ({ $theme }) => ({
  background: $theme.background3,
  borderRadius: 5,
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 0,
  color: 'white',
  textAlign: 'center',
  minWidth: 300,
  maxWidth: '80%'
}));

const Row = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 10,
  color: $theme.foreground,
  opacity: 0.5,
  width: 300,
  textAlign: 'left'
}));

const ButtonRow = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: 300,
  gap: 15,
  marginTop: 20
}));

export const UniversalAuth = React.memo(function UniversalAuth (): JSX.Element {
  const { uuid } = useParams<{ uuid: string }>();

  if (uuid == null) {
    throw new Error('No UUID');
  }

  const loginMutation = seamlessClient.useMutation<LoginBP.Plug>(LoginBP);
  const getSSOAppConsentDetailsQuery = seamlessClient.useQuery<BP.Plug>(BP, { appUUID: uuid });

  const onCancel = React.useCallback(() => {
    window.location.href = '/';
  }, []);

  const showAlert = useAlertDialog();
  const onContinue = React.useCallback(() => {
    loginMutation.call({ appUUID: uuid }).then((result) => {
      window.location.href = result.redirectURI;
    }).catch((error) => {
      void showAlert({
        title: 'Error',
        message: error.message
      });
    });
  }, [loginMutation, showAlert, uuid]);

  return (
    <Page>
      <div style={{
        height: 40,
        flexShrink: 0,
        flexGrow: 0
      }}/>
      {getSSOAppConsentDetailsQuery.isLoading
        ? <LoadingSpinner/>
        : getSSOAppConsentDetailsQuery.isError
          ? <Card>
        There was an error, you cannot be universally authenticated right now
          </Card>
          : <Card>
            <Typography.Label style={{ marginBottom: 0 }}>Login to</Typography.Label>
            <Typography.Header>{getSSOAppConsentDetailsQuery.data.appName}</Typography.Header>
            <Typography.Label>With your Hiyllo Account</Typography.Label>
            <div style={{ height: 10 }}/>
            <Row>
              <FontAwesomeIcon icon={faInfoCircle} />
              <div style={{ flexGrow: 1 }}>This app is operated by {getSSOAppConsentDetailsQuery.data.organization.legalOwner}</div>
            </Row>
            <div style={{ height: 7.5 }}/>
            <Row>
              <FontAwesomeIcon icon={faShield} />
              <div>Hiyllo will provide this app with your Email Address</div>
            </Row>
            <ButtonRow>
              <Button isSecondary label="Cancel" onClick={onCancel} style={{
                width: 0,
                flexGrow: 1
              }}/>
              <Button label="Continue" onClick={onContinue} style={{
                width: 0,
                flexGrow: 1
              }}/>
            </ButtonRow>
          </Card>
      }
      <img src="https://cdn.hiyllo.net/logo/hiyllo/icon-white.png" style={{
        height: 20,
        marginTop: 20,
        opacity: 0.5
      }}/>
    </Page>
  );
});
